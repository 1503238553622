<template>
  <div class="order_wrapper">
    <div>
      <div class="base_box node_box">
        <div class="switch_box both_side">
          <div>
            <div class="align_center delay">
              <img src="../assets/img/wifi.png" alt="" srcset="" />{{nodeTime}} ms
            </div>
            <div class="link">{{ rpcUrl }}</div>
          </div>
          <div class="switch_btn" @click="clickNode">切換節點</div>
        </div>
        <div class="base_title">選擇公鏈</div>
        <div
          class="base_select mt20"
          :class="{ select_active: show1 }"
        >
        <div class="content">
            <img
              :src="chainObj[selectChain].logo"
              class="select_img"
              srcset=""
            />{{ chainObj[selectChain].chainName }}
          </div>
          <div class="right_down" @click.stop="show1 = !show1,show2=false"></div>
          <div class="downlist" v-click-outside="handleScroll">
            <div
              class="list_item"
              v-for="(item, index) in chains"
              @click="switchChain(item.chain)"
              :key="index"
            >
              <img :src="item.logo" class="select_img" srcset="" />{{
                item.chainName
              }}
            </div>
          </div>
        </div>
        <div class="base_title">選擇交易所</div>
        <div
          class="base_select mt20"
          :class="{ select_active: show2 }"
        >
          <div class="content">
            <img
              :src="exchange[selectEx].logo"
              class="select_img"
              srcset=""
            />{{ exchange[selectEx].name }}
          </div>
          <div class="right_down" @click.stop="show2 = !show2,show1=false"></div>
          <div class="downlist" v-click-outside="handleScroll">
            <div
              class="list_item"
              v-for="(item, index) in exchange"
              @click="switchExchange(index)"
              :key="index"
            >
              <img :src="item.logo" class="select_img" srcset="" />{{
                item.name
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="base_box private_box">
        <div class="align_center">
          <div class="amount_item">
            <div class="title">{{chainObj[selectChain].Symbol}} 餘額</div>
            <div class="text">{{wallet.showBalance}}</div>
          </div>
          <div class="amount_item">
            <div class="title">USDT 餘額</div>
            <div class="text">{{wallet.showUsdtBalance}}</div>
          </div>
          <div class="amount_item" v-if="tokenOutInfo.symbol">
            <div class="title">{{tokenOutInfo.symbol}} 餘額</div>
            <div class="text">{{showTokenBalance}}</div>
          </div>
        </div>
        <div class="expiree_time">
          &nbsp;&nbsp;•&nbsp;&nbsp;{{ $t("tool.22") }} {{ showEndTime }}
        </div>
        <div class="base_btn load_btn" @click="clickPrivateKey">載入私鑰</div>
        <div class="load_desc">
          <li>強烈推薦使用小額資產的錢包進行搶幣</li>
          <li>載入私鑰是為了省去確認操作，直接實現快速自動買賣</li>
        </div>
      </div>
    </div>
    <div class="base_box main_box">
      <div class="tabs_box">
        <div class="tabs_item" :class="{tabs_act: buyType == 'buy'}" @click="switchBuy('buy')">{{selectToken.Symbol}} 買入</div>
        <div class="tabs_item" :class="{tabs_act: buyType == 'sell'}" @click="switchBuy('sell')">卖出 {{tokenOutInfo.symbol}}</div>
      </div>
      <div class="col_2 mt20">
        <div class="col_2_item">
          <div class="base_title">交易幣種</div>
          <div
            class="base_select2"
            :class="{ select_active2: show3 }">
            <img :src="selectToken.logo" class="select_img" srcset="" />
            <div>
              <div class="content">
                {{ selectToken.Symbol }}
                <div class="right_down" @click.stop="show3 = !show3"></div>
              </div>
              <div class="name">{{ selectToken.name }}</div>
            </div>
            <div class="downlist" v-click-outside="handleScroll">
              <div class="list_item"
               v-for="(item, index) in swapTokens"
              @click="selToken(index)"
              :key="index">
                <img
                  :src="item.logo"
                  class="select_img"
                  srcset=""
                />
                <div>
                  <div class="content"> {{ item.Symbol }}</div>
                  <div class="name">{{ item.name }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col_2_item">
          <div class="base_title">流動池</div>
          <div class="base_select2" style="font-weight: bold;">
            <div>{{$t('tool.28')}}<br>({{tokenList.join('/')}}）{{$t('tool.29')}}</div>
          </div>
        </div>
      </div>
      <div class="base_title">合約地址</div>
      <div class="base_flex mt20">
        <div class="base_input">
          <input type="text"  v-model="tokenOut" @blur="confirmTokenOut" placeholder="請輸入合约地址" />
        </div>
        <div class="base_btn approve_btn" @click="clickApprove">提前授權</div>
      </div>
      <div class="col_2 mt20">
        <div class="col_2_item">
          <div class="base_title">{{buyType=='buy'?$t('tool.24'):$t('tool.25')}}{{$t('tool.33')}}{{amountIn? ('≈'+(tradeNum?tradeNum*amountIn:amountIn)+ (buyType=='buy' ? selectToken.Symbol:tokenOutInfo.symbol?tokenOutInfo.symbol:'-')):''}}</div>
          <div class="base_input">
            <input type="text" v-model="amountIn" 
              :placeholder="$t('tool.34')+ '（' +(buyType=='buy' ? selectToken.Symbol:tokenOutInfo.symbol?tokenOutInfo.symbol:'-')+'）'"
             />
          </div>
        </div>
        <div class="col_2_item">
          <div class="base_title align_center">
        {{buyType=='buy'?$t('tool.24'):$t('tool.25')}}{{$t('tool.35')}}
         <Tooltip max-width="300" :content="$t('tool.422', [chainObj[selectChain].Symbol])" placement="top">
            <img src="../assets/img/question.png" class="quest" srcset />
              </Tooltip>
      </div>
          <div class="base_input">
            <input type="text" v-model="tradeNum" :placeholder="$t('tool.36', [buyType=='buy'?$t('tool.24'):$t('tool.25')])" />
          </div>
        </div>
      </div>
      <div class="col_2 mt20">
        <div class="col_2_item">
          <div class="both_side">
            <div class="base_title">掛單價格</div>
            <div class="check_box" @click="selectPrice(!priceSelect)">
                <img
                  src="../assets/img/checkbox.png"
                  class="select_icon"
                  srcset
                  v-show="priceSelect"
                />
                <img
                  src="../assets/img/checkbox_unchecked.png"
                  class="select_icon"
                  srcset
                  v-show="!priceSelect"
                />
                {{$t('tool.39')}}
              </div>
          </div>
          <div class="base_input">
            <input type="text" :class="{through:priceSelect}" v-model="priceInput" :disabled="priceSelect"
                  @input="priceChange" placeholder="輸入掛單價格（USDT）" />
          </div>
        </div>
        <div class="col_2_item">
          <div class="base_title">當前價格（USDT）</div>
          <div class="base_input">
            <input type="text" v-model="showTokenPrice" disabled placeholder="當前價格（USDT）" />
          </div>
        </div>
      </div>
      
      <div class="base_title">搶購速度</div>
      <div style="padding: 0 7px" class="mt20">
        <vue-slider :min="1" :interval="0.1" :tooltip-formatter="formatter1" v-model="gasMulti" height="8px"></vue-slider>
      </div>
      <div class="base_tips">
        <img src="../assets/img/info2.png" class="base_info2" srcset="" />{{
          $t("tool.44", [
            gasMulti,
            formatDecimal(gasPrice * gasMulti) + chainObj[selectChain].Symbol,
            formatDecimal(gasPrice * 2 * gasMulti) + chainObj[selectChain].Symbol,
          ])
        }}
      </div>
      <div class="col_2  mt20 col_mobile2">
        <div class="col_2_item">
          <!-- Hash检查 -->
          <div class="switch_box">
            <span class="name">{{$t('scientist.4')}}</span>
            <Tooltip
              max-width="300"
              :content="$t('scientist.5')"
              placement="top"
            >
              <img src="../assets/img/question.png" class="quest" srcset />
            </Tooltip>
            <i-switch
              size="large"
              :disabled="status"
              @on-change="change"
            />
          </div>
        </div>
        <div class="col_2_item">
          <!-- 失败重试 -->
          <div class="switch_box">
            <span class="name">{{$t('sale.6')}}</span>
            <Tooltip
              max-width="300"
              :content="$t('sale.7')"
              placement="top"
            >
              <img src="../assets/img/question.png" class="quest" srcset />
            </Tooltip>
            <i-switch
              size="large"
              v-model="failStatus" @on-change="changeFail"
            />
          </div>
        </div>

      </div>
      <!-- 轮训间隔 (毫秒)-->
          <div class="switch_box mt20 ">
            <div class="align_center ">
              <span class="name">{{$t('order.1')}}</span>
              <Tooltip max-width="300" :content="$t('order.2')" placement="top">
                <img src="../assets/img/question.png" class="quest" srcset />
              </Tooltip>
              <div class="base_input" style="margin-left:10px">
              <input
                type="text"
                v-model="rotationInterval"
                placeholder="0ms"
                onkeyup="value=value.replace(/[^a-z0-9_]/g,'');"
              />
            </div>
            </div>
            
          </div>
      
      <div class="base_title">
        {{$t('order.3')}}
      </div>
      <div class="base_input mt20">
        <input type="text"
            v-model="slige" :placeholder="$t('order.4')" />
      </div>
     
      <div class="base_btn2" @click="refreshTokenPrice">
        {{ $t('tool.54') }}
      </div>
      <div class="base_btn2" v-if="isLoading"  @click="stopBuy">
        取消執行
      </div>
    </div>
    <Modal
      v-model="nodeModal"
      class-name="node_modal"
      footer-hide
      :closable="false"
      width="600"
    >
        <div class="modal_header">
          <div class="modal_title">
            <img src="../assets/img/node_icon.png" alt="" srcset="">切換節點
          </div>
          <img
            src="../assets/img/vipclose.png"
            class="close_icon"
            srcset
            @click="nodeModal = false"
          />
        </div>
        <div class="align_center">
          <div class="base_input" style="flex: 1">
            <input
              type="text"
              v-model="nodeUrl"
              :placeholder="$t('tool.14')"
            />
          </div>
          <span
            class="base_btn"
            style="width: 100px; margin-left: 20px"
            @click="switchNode"
            >確認</span
          >
        </div>
    </Modal>
    <Modal
      v-model="keyModal"
      class-name="node_modal"
      footer-hide
      :closable="false"
      width="600"
    >
        <div class="modal_header">
          <div class="modal_title">
            <img src="../assets/img/private_icon.png" alt="" srcset="">加載私鑰
          </div>
          <img
            src="../assets/img/vipclose.png"
            class="close_icon"
            srcset
            @click="keyModal = false"
          />
        </div>
        <div class="align_center">
          <div class="base_input" style="flex: 1">
          <input
            :type="password?'text':'password'"
            v-model="privateKey"
            :placeholder="$t('tool.14')"
          />
          <img src="../assets/img/er_hide.png" class="er_img" v-show="password" @click="password=!password" alt="" srcset="">
          <img src="../assets/img/er_show.png" class="er_img" v-show="!password" @click="password=!password" alt="" srcset="">
        </div>
          <span
            class="base_btn"
            style="width: 100px; margin-left: 20px"
            @click="confirmPrivateKey"
            >確認</span
          >
        </div>
    </Modal>
  </div>
</template>

<script>
import BN from "bn.js";
import {
  VipSale_ABI,
  VipSale,
  tokenABI,
  ERC20_ABI,
  Common_ABI,
  SwapRouter_ABI,
  ZERO_ADDRESS,
  MAX_INT,
  CheckVipRpc
} from "../config";

import {
  showFromWei,
  showFromWei2,
  showAccount,
  showFromWeiMore,
  formatDecimal,
  multiply,
  toWeiMore,
  toWei,
  parseTime,
  clickOutside
} from "../utils";

import { chainObj2 as chainObj } from "../config";

var chainsO = {
  128: "Heco",
  56: "BSC",
  1: 'Ethereum',
  10001: 'EthereumPoW'
};
var chainsO2 = {
  Heco: 128,
  BSC: 56,
  Ethereum: 1,
  EthereumPoW: 10001
};
var selectChainId = 56,
  selectChain = "BSC";
export default {
  directives: {clickOutside},
  props: {
    address: {
      type: String
    },
    chainId: {
      type: [String, Number]
    }
  },
  data() {
    return {
            formatter1: '{value}倍',

      password: false,
      selectFlag: false,
      value: 0,

      rotationInterval: 1000,
      status: false,
      // 燃gas模式开关
      gasMode: false,
      // 燃烧间隔
      gasInterval: 1000,
      // 买入失效(秒)
      failTime: 20,
      // 延迟下单
      delayStatus: false,
      delayInterval: 1500,

      //支持的链
      // chains: ["Heco", "BSC"],
      chainObj,

      selectEx: 0,
      show1: false,
      show2: false,
      show3: false,
      status: false,
      failStatus: false,
      funStatus: false,
      rotationInterval: 3000,
      selected: false,
      nodeTime: 212,
      rpcUrl: chainObj[selectChain].RPC,
      //VipSale合约调用的Rpc，BSC链的RPC，如果Rpc不可用会影响功能，需要设置为可修改的
      nodeModal: false,
      nodeUrl: "",
      keyModal: false,
      isLoading: false,
      isLoadingFun: null,
      privateKey: "",
      password: false,
      chainsO,
      //当前选择的链符号
      selectChain,
      selectChainId,
      //当前选择的链配置
      chainConfig: chainObj[selectChain],
      USDTDetail: {},
      //是否Vip
      isVip: false,
      showEndTime: "-",
      //兑换的币种数组，一般是主币和USDT
      swapTokens: [],
      //当前选择的兑换代币，一般是主币或者USDT
      selectToken: chainObj[selectChain].Tokens[0],
      //价值币数组，用于查价格或者代币滑点
      Tokens: chainObj[selectChain].Tokens,
      //输入框代币合约地址
      tokenOut: null,
      //输入框代币合约对应的代币信息
      tokenOutInfo: {},
      showTokenBalance: 0,
      //挂单价格
      priceInput: null,
      showTokenPrice: null,
      // 设置现价开关
      priceSelect: true,
      // 交易次数
      tradeNum: null,
      //交易数量
      amountIn: null,
      //交易滑点，默认20%
      slige: null,
      //gas倍数，默认1倍
      gasMulti: 1,
      gasPrice: null,
      //使用固定的gasLimit估算手续费
      gasLimit: "1000000",
      // 滑点
      amountOut: null,
      //路由
      swapRouter: chainObj[selectChain].Dexs[0].SwapRouter,
      wallet: {},
      isFirst: true,
      buyType: "buy",
      _refreshTokenPriceIntervel: null,
      checking: false
    };
  },
  computed: {
    chains() {
      return Object.values(this.chainObj)
    },
    exchange() {
      return this.chainObj[this.selectChain].Dexs;
    },
    tokenList() {
      return this.chainObj[this.selectChain].Tokens.map(item => item.Symbol);
    },
  },
  watch: {
    address() {
      if (this.address) {
        this.getTime();
      }
    }
    // chainId() {
    //   if (this.chainId) {
    //     this.handleAccountsChanged();
    //   }
    // }
  },
  mounted() {

    // window.addEventListener("scroll", this.handleScroll, true);

    console.log(11111, this.chainId, this.address);

    if (this.address) {
      this.getTime();
    }
    // if (this.chainId) {
    //   this.clearRefreshTokenPriceInterval();
    this.handleAccountsChanged();
    // }
  },
  beforeDestroy() {
    this.clearRefreshTokenPriceInterval();
    // window.removeEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    formatDecimal,
     inputTip() {
      if (this.gasMode) return this.$message.error("烧Gas模式下不可用");
    },
    changeDelay(status) {
      this.delayStatus = status;
    },
    changeGasMode(status) {
      this.gasMode = status;
      if (status) {
        // 流动池最小数量、Hash检查、监控、买入失效、延迟下单
        this.tokenUValue = null;
        this.delayStatus = false;
        this.status = false;
        this.tradeNum = null;
      }
    },
    change(status) {},
    // 挂单价格输入，设置现价取消
    priceChange() {
      if (this.priceInput) {
        this.priceSelect = false;
      }
    },
    selectPrice(flag) {
      this.priceSelect = flag;
      this.priceInput = null;
    },
    // 增加轮询间隔
    addInter() {
      this.rotationInterval = parseInt(this.rotationInterval) + 1;
    },
    minusInter() {
      if (this.rotationInterval == 3000) return;
      this.rotationInterval = parseInt(this.rotationInterval) - 1;
    },
    clickMax() {
      this.amountIn = this.showTokenBalance
    },
    //确定要购买的代币合约
    async confirmTokenOut() {
      let tokenAddress = this.tokenOut;
      if (!tokenAddress) {
        this.$message.error("請輸入要购买的代币合约");
        return;
      }
      this.message = this.$message({
          type: 'loading',
          message: 'loading',
          duration: 0
        })
      try {
        let options = {
          timeout: 600000, // milliseconds,
          headers: [{ name: "Access-Control-Allow-Origin", value: "*" }]
        };
        var Web3 = require("web3");
        const myWeb3 = new Web3(
          new Web3.providers.HttpProvider(this.rpcUrl, options)
        );
        const commonContract = new myWeb3.eth.Contract(
          Common_ABI,
          this.chainConfig.Common
        );

        //获取要购买的代币信息
        let tokensInfo = await commonContract.methods
          .getTokenInfo(tokenAddress)
          .call();
        let symbol = tokensInfo[0];
        let decimals = tokensInfo[1];
        let totalSupply = tokensInfo[2];

        let tokenOutInfo = {
          address: tokenAddress,
          symbol: symbol,
          decimals: decimals,
          totalSupply: totalSupply,
          showTotal: showFromWei(totalSupply, decimals, 2)
        };
        this.tokenOutInfo = tokenOutInfo;
        console.log("tokenOutInfo", tokenOutInfo);

        //获取价格
        let priceInfo = await this.getTokenPrice(tokenOutInfo);
        console.log(priceInfo);
        // this.setState({
        //   tokenOutInfo: priceInfo
        // });
        this.tokenOutInfo = priceInfo;

        //获取钱包余额
        if (this.wallet.privateKey) {
          this.getWalletBalance();
        }
      } catch (e) {
        console.log("e", e);
        this.tokenOutInfo = {}
        this.$message.error('無效地址');
      } finally {
        this.message.close()
      }
    },
    //获取代币价格
    async getTokenPrice(tokenInfo) {
      let options = {
        timeout: 600000, // milliseconds,
        headers: [{ name: "Access-Control-Allow-Origin", value: "*" }]
      };
      console.log("getTokenPrice", this.chainConfig);
      var Web3 = require("web3");
      const myWeb3 = new Web3(
        new Web3.providers.HttpProvider(this.rpcUrl, options)
      );
      const commonContract = new myWeb3.eth.Contract(
        Common_ABI,
        this.chainConfig.Common
      );
      //获取要购买的代币价格
      let tokens = this._getTokensAddress();
      let tokenPriceResult = await commonContract.methods
        .getTokenPrice(
          this.swapRouter,
          tokenInfo.address,
          this.chainConfig.USDT,
          tokens
        )
        .call();
      console.log("tokenPriceResult", tokenPriceResult);
      //代币价格，需要处理USDT最小精度
      let tokenPrice = new BN(tokenPriceResult[0], 10);
      //价格的精度，本来就需要处理USDT的精度，这个精度是在USDT精度的基础上多的，还要再处理一遍
      //价格小于0.{17个0}x时，才存在这个精度
      let priceDecimals = parseInt(tokenPriceResult[1]);
      let pairOther = tokenPriceResult[2];
      let tokenReserve = tokenPriceResult[3];

      tokenInfo.tokenPrice = tokenPrice;
      tokenInfo.priceDecimals = priceDecimals;
      tokenInfo.pairOther = pairOther;
      tokenInfo.tokenReserve = tokenReserve;
      let realDecimals = this.USDTDetail.decimals + priceDecimals;
      console.log("realDecimals", this.USDTDetail.decimals, priceDecimals);
      tokenInfo.showTokenPrice = showFromWeiMore(
        tokenPrice,
        realDecimals,
        realDecimals
      );
      this.showTokenPrice = tokenInfo.showTokenPrice;
      return tokenInfo;
    },
    //获取其他价值币的合约地址，用于调用合约
    _getTokensAddress() {
      let Tokens = this.Tokens;
      let len = Tokens.length;
      let tokensAddress = [];
      for (let i = 0; i < len; i++) {
        tokensAddress.push(Tokens[i].address);
      }
      return tokensAddress;
    },
    stopBuy() {
      this.isLoading = false;
      // this.isLoadingFun()
      this.message.close()
      this.clearRefreshTokenPriceInterval();
    },
    async clickApprove() {
      if (!this.privateKey) {
        this.$message.error("請輸入私钥导入钱包");
        return;
      }
      if (!this.isVip) {
        this.$message.error("仅限VIP使用");
        return;
      }
      
      if (!this.tokenOut) {
        this.$message.error("請輸入代币合约地址");
        return;
      }
      this.checkVip();
      if (this.buyType == "buy") {
        let selectToken = this.selectToken;
        if (selectToken.address == this.chainConfig.WETH) {
          //主币余额不足
          // if (
          //   this.wallet.balance.lte(toWei(this.amountIn, selectToken.decimals))
          // ) {
          //   this.$message.error(selectToken.Symbol + "余额不足");
          //   return;
          // }
        } else {
          //USDT余额不足
          // if (
          //   this.wallet.usdtBalance.lt(
          //     toWei(this.amountIn, selectToken.decimals)
          //   )
          // ) {
          //   this.$message.error("USDT余额不足");
          //   return;
          // }
          await this.approve(this.USDTDetail.address, true);
        }
      } else if (this.buyType == "sell") {
        // if (
        //   this.wallet.tokenBalance.lt(
        //     toWei(this.amountIn, this.tokenOutInfo.decimals)
        //   )
        // ) {
        //   this.$message.error(this.tokenOutInfo.symbol + "余额不足");
        //   return;
        // }
        await this.approve(this.tokenOutInfo.address, true);
      }
    },
    async refreshTokenPrice() {
      this.clearRefreshTokenPriceInterval();
      if (!this.privateKey) {
        this.$message.error("請輸入私钥导入钱包");
        return;
      }
      if (!this.isVip) {
        this.$message.error("仅限VIP使用");
        return;
      }
      if (!this.amountIn) {
        this.$message.error("請輸入交易数量");
        return;
      }
      if (!this.tokenOutInfo || !this.tokenOutInfo.symbol) {
        this.$message.error("請輸入代币合约地址");
        return;
      }

      if (!this.priceInput && !this.priceSelect) {
        this.$message.error("請輸入挂单价格");
        return;
      }
      this.isLoading = true;
      this.message = this.$message({
          type: 'loading',
          message: 'loading',
          duration: 0
        })
      let wallet = this.wallet;
      
      if (this.buyType == "buy") {
        let selectToken = this.selectToken;
        if (selectToken.address == this.chainConfig.WETH) {
          //主币余额不足
          if (
            this.wallet.balance.lte(toWei(this.amountIn, selectToken.decimals))
          ) {
            this.message.close()
            this.isLoading = false;
            this.$message.error(selectToken.Symbol + "余额不足");

            return;
          }
        } else {
          //USDT余额不足
          if (
            this.wallet.usdtBalance.lt(
              toWei(this.amountIn, selectToken.decimals)
            )
          ) {
            this.message.close()
            this.isLoading = false;
            this.$message.error("USDT余额不足");
            return;
          }
          await this.approve(this.USDTDetail.address, false);
        }
      } else if (this.buyType == "sell") {
        if (
          this.wallet.tokenBalance.lt(
            toWei(this.amountIn, this.tokenOutInfo.decimals)
          )
        ) {
          this.message.close()
          this.isLoading = false;
          this.$message.error(this.tokenOutInfo.symbol + "余额不足");
          return;
        }
        await this.approve(this.tokenOutInfo.address, false);
      }
      this._refreshTokenPriceIntervel = setInterval(() => {
        this._refreshTokenPrice();
      }, this.rotationInterval);
    },
    //刷新代币价格
    async _refreshTokenPrice() {
      if (this.checking) {
        return;
      }
      this.checking = true;
      try {
        this.getWalletBalance();
        let tokenInfo = await this.getTokenPrice(this.tokenOutInfo);

        this.tokenOutInfo = tokenInfo;
        let price = toWeiMore(tokenInfo.showTokenPrice);
        if (price.isZero() && tokenInfo.priceDecimals == 0) {
          return;
        }
        let priceInput;
        if (this.priceSelect) {
          priceInput = toWeiMore(this.showTokenPrice);
        } else {
          priceInput = toWeiMore(this.priceInput);
        }
        console.log("priceInput", priceInput.toString());
        console.log("price", price.toString());
        let tradeNum = this.tradeNum;
        if (!tradeNum) {
          tradeNum = 1;
        }
        let that = this
        if (this.buyType == "buy" && price.lte(priceInput)) {
          this.clearRefreshTokenPriceInterval();
          // for (let i = 0; i < tradeNum; i++) {
          //   this._swap(i);
          // }
          for (var i = 0; i < tradeNum; i++) {
            (function (i) {
              setTimeout(() => {
                console.log(i);
                that._swap(i);
              }, i * 1000);
            })(i + 1)
          }
        } else if (this.buyType == "sell" && price.gte(priceInput)) {
          this.clearRefreshTokenPriceInterval();
          // for (let i = 0; i < tradeNum; i++) {
          //   this._swap(i);
          // }
          for (var i = 0; i < tradeNum; i++) {
            (function (i) {
              setTimeout(() => {
                console.log(i);
                that._swap(i);
              }, i * 1000);
            })(i + 1)
          }
        }
      } catch (e) {
        console.log("e", e);
      } finally {
        this.checking = false;
      }
    },
    async _swap(num) {
      console.log("_swap", num)
       this.message = this.$message({
          type: 'loading',
          message: 'loading',
          duration: 0
        })
      try {
       

        let wallet = this.wallet;
        var Web3 = require("web3");
        const myWeb3 = new Web3(
          new Web3.providers.HttpProvider(this.rpcUrl)
        );
        const swapContract = new myWeb3.eth.Contract(
          SwapRouter_ABI,
          this.swapRouter
        );

        let amountIn;
        let path = [];
        //当前选择的交易币种
        let selectToken = this.selectToken;
        //当前代币合约信息
        let tokenOutInfo = this.tokenOutInfo;
        if (this.buyType == "buy") {
          //输入
          amountIn = toWei(this.amountIn, selectToken.decimals);
          //路径
          path.push(selectToken.address);
          if (tokenOutInfo.pairOther != selectToken.address) {
            path.push(tokenOutInfo.pairOther);
          }
          path.push(tokenOutInfo.address);
        } else {
          //sell
          //输入
          amountIn = toWei(this.amountIn, tokenOutInfo.decimals);
          //路径
          path.push(tokenOutInfo.address);
          if (tokenOutInfo.pairOther != selectToken.address) {
            path.push(tokenOutInfo.pairOther);
          }
          path.push(selectToken.address);
        }

        //预估能得到多少代币
        let amountOuts = await swapContract.methods
          .getAmountsOut(amountIn, path)
          .call();
        let amountOut = new BN(amountOuts[amountOuts.length - 1], 10);
        //滑点
        let slige = this.slige;
        if (!slige) {
          slige = "20";
        }
        slige = parseInt(parseFloat(slige) * 100);
        //根据滑点计算得到的最小值
        let amountOutMin = amountOut
          .mul(new BN(10000 - slige))
          .div(new BN(10000));

        var gasPrice = await myWeb3.eth.getGasPrice();
        gasPrice = new BN(gasPrice, 10);
        //gas倍数
        let gasMulti = this.gasMulti;
        if (!gasMulti) {
          gasMulti = 1;
        }
        gasMulti = parseFloat(gasMulti);
        gasMulti = parseInt(gasMulti * 100);
        gasPrice = gasPrice.mul(new BN(gasMulti)).div(new BN(100));

        //Data
        let data;
        if (this.buyType == "buy") {
          //主币购买
          if (selectToken.address == this.chainConfig.WETH) {
            data = swapContract.methods
              .swapExactETHForTokensSupportingFeeOnTransferTokens(
                amountOutMin,
                path,
                wallet.address,
                1914823077
              )
              .encodeABI();
          } else {
            data = swapContract.methods
              .swapExactTokensForTokensSupportingFeeOnTransferTokens(
                amountIn,
                amountOutMin,
                path,
                wallet.address,
                1914823077
              )
              .encodeABI();
          }
        } else {
          //卖得主币
          if (selectToken.address == this.chainConfig.WETH) {
            data = swapContract.methods
              .swapExactTokensForETHSupportingFeeOnTransferTokens(
                amountIn,
                amountOutMin,
                path,
                wallet.address,
                1914823077
              )
              .encodeABI();
          } else {
            data = swapContract.methods
              .swapExactTokensForTokensSupportingFeeOnTransferTokens(
                amountIn,
                amountOutMin,
                path,
                wallet.address,
                1914823077
              )
              .encodeABI();
          }
        }

        var nonce = await myWeb3.eth.getTransactionCount(
          wallet.address,
          "pending"
        );
        console.log("nonce", nonce);

        let gas;
        if (this.buyType == "buy") {
          //主币购买
          if (selectToken.address == this.chainConfig.WETH) {
            gas = await swapContract.methods
              .swapExactETHForTokensSupportingFeeOnTransferTokens(
                amountOutMin,
                path,
                wallet.address,
                1914823077
              )
              .estimateGas({ from: wallet.address, value: amountIn });
          } else {
            gas = await swapContract.methods
              .swapExactTokensForTokensSupportingFeeOnTransferTokens(
                amountIn,
                amountOutMin,
                path,
                wallet.address,
                1914823077
              )
              .estimateGas({ from: wallet.address });
          }
        } else {
          //卖得主币
          if (selectToken.address == this.chainConfig.WETH) {
            gas = await swapContract.methods
              .swapExactTokensForETHSupportingFeeOnTransferTokens(
                amountIn,
                amountOutMin,
                path,
                wallet.address,
                1914823077
              )
              .estimateGas({ from: wallet.address });
          } else {
            gas = await swapContract.methods
              .swapExactTokensForTokensSupportingFeeOnTransferTokens(
                amountIn,
                amountOutMin,
                path,
                wallet.address,
                1914823077
              )
              .estimateGas({ from: wallet.address });
          }
        }
        gas = new BN(gas, 10).mul(new BN("150", 10)).div(new BN("100", 10));

        let value = "0";
        if (
          this.buyType == "buy" &&
          selectToken.address == this.chainConfig.WETH
        ) {
          value = amountIn;
        }

        var txParams = {
          gas: Web3.utils.toHex(gas),
          gasPrice: Web3.utils.toHex(gasPrice),
          nonce: Web3.utils.toHex(nonce),
          chainId: this.chainConfig.ChainId,
          value: Web3.utils.toHex(value),
          to: this.swapRouter,
          data: data,
          from: wallet.address
        };

        var fee = new BN(gas, 10).mul(new BN(gasPrice, 10));
        console.log("fee", Web3.utils.fromWei(fee, "ether"));

        //交易签名
        let privateKey = wallet.privateKey;
        var signedTx = await myWeb3.eth.accounts.signTransaction(
          txParams,
          privateKey
        );
        console.log("signedTx", signedTx);
        console.log("txParams", txParams);
        let transaction = await myWeb3.eth.sendSignedTransaction(
          signedTx.rawTransaction
        );
        console.log("transaction", transaction);
        myWeb3.eth.getTransactionReceipt(transaction.transactionHash).then(
          function(value) {
            console.log(value, "v");
          },
          function(error) {
            console.log(error, "e");
          }
        );
        // 交易失败
        if (!transaction.status) {
          this.$message.error(this.$t("lang.fail1"));
          // 失败重试
          if (this.failStatus) {
            this._swap(num);
          }
          return;
        }
        console.log("交易成功");
        this.$message('交易成功');
        this.getWalletBalance();
      } catch (e) {
        console.log("e", e);
        // 失败重试
          if (this.failStatus) {
            this._swap(num);
          }
      } finally {
        if (this.tradeNum == num || this.tradeNum == null){
          this.isLoading = false;
          this.message.close()
        }
      }
    },
    clearRefreshTokenPriceInterval() {
      if (this._refreshTokenPriceIntervel) {
        clearInterval(this._refreshTokenPriceIntervel);
        this._refreshTokenPriceIntervel = null;
      }
    },
    async approve(tokenAddress, flag) {
      let wallet = this.wallet;
      if (!wallet || !wallet.privateKey) {
        this.$message.error("请先输入私钥导入钱包");
        return;
      }
      let msg = null
        if(flag) {
           this.message = this.$message({
          type: 'loading',
          message: 'loading',
          duration: 0
        })
        }
       
      try {
        let options = {
          timeout: 600000, // milliseconds,
          headers: [{ name: "Access-Control-Allow-Origin", value: "*" }]
        };
        var Web3 = require("web3");
        const myWeb3 = new Web3(
          new Web3.providers.HttpProvider(this.rpcUrl, options)
        );
        const tokenContract = new myWeb3.eth.Contract(ERC20_ABI, tokenAddress);
        let allowance = await tokenContract.methods
          .allowance(wallet.address, this.swapRouter)
          .call();
        allowance = new BN(allowance, 10);
        if (!allowance.isZero()) {
          if(flag) {
             this.message.close()
            this.$message.error("已授权");
          }
          return;
        }
        var gasPrice = await myWeb3.eth.getGasPrice();
        gasPrice = new BN(gasPrice, 10);

        var gas = await tokenContract.methods
          .approve(this.swapRouter, MAX_INT)
          .estimateGas({ from: wallet.address });
        gas = new BN(gas, 10).mul(new BN("120", 10)).div(new BN("100", 10));

        //Data
        var data = tokenContract.methods
          .approve(this.swapRouter, new BN(MAX_INT, 10))
          .encodeABI();

        var nonce = await myWeb3.eth.getTransactionCount(
          wallet.address,
          "pending"
        );

        var txParams = {
          gas: Web3.utils.toHex(gas),
          gasPrice: Web3.utils.toHex(gasPrice),
          nonce: Web3.utils.toHex(nonce),
          chainId: this.chainConfig.ChainId,
          value: Web3.utils.toHex("0"),
          to: tokenAddress,
          data: data,
          from: wallet.address
        };

        var fee = new BN(gas, 10).mul(new BN(gasPrice, 10));

        console.log("txParams", txParams);

        //交易签名
        let privateKey = wallet.privateKey.trim();
        var signedTx = await myWeb3.eth.accounts.signTransaction(
          txParams,
          privateKey
        );
        console.log("signedTx", signedTx);
        let transaction = await myWeb3.eth.sendSignedTransaction(
          signedTx.rawTransaction
        );
        //交易失败
        if (!transaction.status) {
          this.$message.error(this.$t('lang.a4'));
          return;
        }
        this.$message("授权成功");
      } catch (e) {
        console.log("e", e);
      } finally {
        if(flag) {
           this.message.close()
        }
      }
    },
    handleScroll() {
      this.show1 = false;
      this.show2 = false;
      this.show3 = false;
    },
    switchBuy(type) {
      if (this.buyType == type) return;
      this.buyType = type;
      this.clearRefreshTokenPriceInterval();
    },
    initData() {},
    clickBuyModal() {
      if (this.chainId != 56) {
        this.$message.error("请连接BSC链钱包购买VIP");
        return;
      }
      this.$refs.modal.showModal();
      this.$refs.modal.getInfo();
    },
    clickNode() {
      this.nodeModal = true;
    },
    checkURL(URL) {
      var str = URL,
        Expression = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/,
        objExp = new RegExp(Expression);
      if (objExp.test(str) == true) {
        return true;
      } else {
        return false;
      }
    },
    async switchNode() {
      if (!this.nodeUrl) {
        this.$message.error("請輸入节点地址");
        return;
      }
      if (!this.checkURL(this.nodeUrl)) {
        this.$message.error("無效的 JSON RPC 響應");
        return;
      }
      try {
        let options = {
          timeout: 600000, // milliseconds,
          headers: [{ name: "Access-Control-Allow-Origin", value: "*" }]
        };
        var Web3 = require("web3");
        const myWeb3 = new Web3(
          new Web3.providers.HttpProvider(this.nodeUrl, options)
        );
        console.log("myWeb3", myWeb3);

        let time = await myWeb3.eth.getGasPrice();
        console.log(time);
        this.rpcUrl = this.nodeUrl;
        this.getTime();
        this.nodeModal = false;
        this.$message(this.$t("lang.success1"));
      } catch (e) {
        console.log(e);
        this.$message.error(e.message);
      }
    },
    // 选择链
    switchChain(chain) {
      if (this.selectChain == chain) return;
      this.selectChain = chain;
      this.selectChainId = chainsO2[chain];
      this.chainConfig = chainObj[chain];
      this.selectToken = chainObj[chain].Tokens[0];
      this.Tokens = chainObj[chain].Tokens;
      this.swapRouter = chainObj[chain].Dexs[0].SwapRouter;
      this.rpcUrl = chainObj[chain].RPC;
      this.gasMulti = 1
      this.handleAccountsChanged();
      this.getTime();
      this.show1 = false;
      this.clearRefreshTokenPriceInterval();
    },
    // 选择交易所
    switchExchange(index) {
      if (this.selectEx == index) return;
      this.selectEx = index;
      this.swapRouter = this.exchange[index].SwapRouter;
      this.show2 = false;
      this.clearRefreshTokenPriceInterval();
    },
    //选择交易币种
    selToken(index) {
      if (this.selectToken.address == this.Tokens[index].address) return;
      this.selectToken = this.Tokens[index];
      this.show3 = false;
      this.clearRefreshTokenPriceInterval();
    },
    changeFun(status) {
      this.funStatus = status;
    },
    changeFail(status) {
      this.failStatus = status;
    },
    change(status) {},
    handleAccountsChanged() {
      let page = this;
      //链发生变化，一些配置信息要重置
      console.log("this.chainId", this.chainId, this.chainConfig.chainId);
      // if (this.chainId != this.chainConfig.chainId) {
      //   this.clearRefreshTokenPriceInterval();
      //   this.selectChainId = this.chainId;
      //   this.selectChain = this.chainsO[this.chainId];
      //   this.chainConfig = this.chainObj[this.selectChain];

      //   this.rpcUrl = this.chainObj[this.selectChain].RPC;
      //   this.swapRouter = this.chainObj[this.selectChain].Dexs[0].SwapRouter;
      //   this.Tokens = this.chainObj[this.selectChain].Tokens;
      //   this.selectToken = this.chainObj[this.selectChain].Tokens[0];
      // }

      //兑换币种
      let swapTokens = [];
      //查找USDT信息
      let USDTDetail;
      //主币和USDT放前面2位
      for (let i = 0; i < 2; i++) {
        let Token = this.Tokens[i];
        swapTokens.push(Token);
        if (Token.address == this.chainConfig.USDT) {
          USDTDetail = Token;
        }
      }
      this.swapTokens = swapTokens;
      this.USDTDetail = USDTDetail;
      console.log("this.USDTDetail", this.USDTDetail);
      //切换链后，重新获取钱包余额
      if (this.privateKey) {
        //配置未更新，延迟请求
        setTimeout(() => {
          this.getWalletBalance();
        }, 100);
      }
    },
    async getTime() {
      var start = new Date().getTime();

      
      var Web3 = require("web3");
      let rpcUrl = this.rpcUrl
      if(this.selectChain == 'OP') {
        rpcUrl = 'https://ethereum.publicnode.com'
      }
      const myWeb3 = new Web3(new Web3.providers.HttpProvider(rpcUrl));

      let gasPrice = await myWeb3.eth.getGasPrice();
      
      this.gasPrice = multiply(myWeb3.utils.fromWei(gasPrice), this.gasLimit)
      if(this.selectChain == 'OP') {
        this.gasPrice = multiply(this.gasPrice,0.05)
      }
      var end = new Date().getTime();
      console.log("cost is", `${end - start}ms`);
      this.nodeTime = end - start;
      this.isFirst = false;
    },
    clickPrivateKey() {
      this.keyModal = true;
    },
    //确定私钥
    async confirmPrivateKey() {
      let privateKey = this.privateKey;
      if (!privateKey) {
        return;
      }
      let options = {
        timeout: 600000, // milliseconds,
        headers: [{ name: "Access-Control-Allow-Origin", value: "*" }]
      };
      var Web3 = require("web3");
      const myWeb3 = new Web3(
        new Web3.providers.HttpProvider(this.rpcUrl, options)
      );
      try {
        //私钥推导账户信息
        let account = myWeb3.eth.accounts.privateKeyToAccount(privateKey);
        let wallet = {
          //私钥
          privateKey: privateKey,
          //地址
          address: account.address,
          //主币余额
          showBalance: 0,
          //USDT余额
          showUsdtBalance: 0,
          showTokenBalance: 0
        };
        //当前钱包
        console.log("私钥钱包", wallet);
        this.wallet = wallet;
        this.keyModal = false;
        this.getWalletBalance();
      } catch (e) {
        console.log("confirmPrivateKey", e.message);
        if(e.message.indexOf('Private key must be 32') > -1) {
          this.$message.error('私鑰必須是 32 字節長');
        } else {
          this.$message.error(e.message);
        }
      }
    },
    //获取钱包主币、USDT余额
    async getWalletBalance() {
      try {
        let options = {
          timeout: 600000, // milliseconds,
          headers: [{ name: "Access-Control-Allow-Origin", value: "*" }]
        };
        let wallet = this.wallet;
        var Web3 = require("web3");
        console.log("this.rpcUrl", this.rpcUrl);

        const myWeb3 = new Web3(
          new Web3.providers.HttpProvider(this.rpcUrl, options)
        );
        //获取USDT余额
        console.log(
          "this.chainObj[this.selectChain].USDT",
          this.chainObj[this.selectChain].USDT
        );
        console.log(this.selectChain);
        const usdtContract = new myWeb3.eth.Contract(
          ERC20_ABI,
          this.chainObj[this.selectChain].USDT
        );
        console.log("wallet.address", wallet.address);
        let usdtBalance = await usdtContract.methods
          .balanceOf(wallet.address)
          .call();
        console.log("usdtBalance", usdtBalance);
        let showUsdtBalance = showFromWei(
          usdtBalance,
          this.USDTDetail.decimals,
          2
        );
        wallet.showUsdtBalance = showUsdtBalance;
        wallet.usdtBalance = new BN(usdtBalance, 10);
        //获取主币余额
        let balance = await myWeb3.eth.getBalance(wallet.address);
        let showBalance = showFromWei(balance, 18, 4);
        wallet.balance = new BN(balance, 10);
        wallet.showBalance = showBalance;
        let tokenOutInfo = this.tokenOutInfo;
        console.log("tokenOutInfo", tokenOutInfo);
        if (tokenOutInfo && tokenOutInfo.address) {
          //获取代币余额
          const tokenContract = new myWeb3.eth.Contract(
            tokenABI,
            tokenOutInfo.address
          );
          let tokenBalance = await tokenContract.methods
            .balanceOf(wallet.address)
            .call();
          let showTokenBalance = showFromWei(
            tokenBalance,
            tokenOutInfo.decimals,
            4
          );
          console.log("tokenBalance", tokenBalance);
          wallet.tokenBalance = new BN(tokenBalance, 10);
          this.showTokenBalance = showTokenBalance;
          wallet.showTokenBalance = showTokenBalance;
        }
        this.wallet = wallet;
        this.checkVip();
      } catch (e) {
        console.log("e", e);
      } finally {
      }
    },
   
    //检测是否VIP，需要用检测VIP的RPC，该RPC，总是BSC链的RPC
    async checkVip() {
      try {
        let wallet = this.wallet;
        var Web3 = require("web3");
        const myWeb3 = new Web3(
          new Web3.providers.HttpProvider(CheckVipRpc)
        );
        const saleContract = new myWeb3.eth.Contract(VipSale_ABI, VipSale);
        //获取用户信息
        const userInfo = await saleContract.methods
          .getUserInfo(wallet.address)
          .call();
        console.log("userInfo", userInfo);
        //Vip过期时间
        let endTime = userInfo[1];
        let showEndTime,
            isVip = true;

          if (new BN(endTime, 10).eq(new BN(MAX_INT, 10))) {
            showEndTime = "永久会员";
          } else if (parseInt(endTime) == 0) {
            showEndTime = "未购买会员";
            isVip = false;
          } else {
            showEndTime = parseTime(parseInt(endTime));
            if(parseInt(new Date().getTime() / 1000) > parseInt(endTime)) {
              isVip = false
            }
          }
        this.isVip = isVip;
        this.showEndTime = showEndTime;
        console.log("this.isVip", this.isVip);
      } catch (e) {
        console.log("e", e);
      } finally {
      }
    },
  }
};
</script>

<style lang="less">
@import "../assets/pc/order.less";
@import "../assets/mobile/order.less";
</style>